import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Grid } from "@material-ui/core"
import SectionTitle from "../SectionTitle"
import SectionText from "../SectionText/SectionText"
import FeatureCard from "../FeatureCard/FeatureCard"
import RequestEarlyAccess from "../RequestEarlyAccess/RequestEarlyAccess"

const useStyles = makeStyles(theme => ({
  root: {},
}))

const Features = ({ title, description, features }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box maxWidth={768} ml="auto" mr="auto" mb={6}>
        <SectionTitle variant="alternate" align="center" color="#ffffff">
          {title}
        </SectionTitle>
        <SectionText color="#ffffff" align="center">
          {description}
        </SectionText>
      </Box>
      <Grid container spacing={3}>
        {features.map(feat => (
          <Grid item xs={12} sm={6} md={3} key={feat.featureTitle}>
            <FeatureCard
              title={feat.featureTitle}
              subFeatures={feat.associatedSubFeatures}
            />
          </Grid>
        ))}
      </Grid>
      <Box textAlign="center" mt={4}>
        <RequestEarlyAccess size="large" />
      </Box>
    </div>
  )
}

Features.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      featureTitle: PropTypes.string,
    })
  ),
}

export default Features
