import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Box } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  photo: {
    width: 150,
    height: 150,
    borderRadius: "50%",
  },
}))

const EmployeeCard = ({ name, position, bio, photo }) => {
  const classes = useStyles()
  return (
    <Box textAlign="center">
      <Box textAlign="center">
        <img
          src={`${photo}?w=200&h=200`}
          alt={name}
          className={classes.photo}
        />
      </Box>
      <Typography variant="h6">{name}</Typography>
      <Typography variant="h6" color="textSecondary" gutterBottom>
        {position}
      </Typography>
      <Typography variant="body1">{bio}</Typography>
    </Box>
  )
}

EmployeeCard.propTypes = {
  bio: PropTypes.string,
  name: PropTypes.string,
  photo: PropTypes.string,
  position: PropTypes.string,
}

export default EmployeeCard
