import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import SectionTitle from "../SectionTitle"
import SectionText from "../SectionText/SectionText"
// import YoutubeVideo from "../YoutubeVideo/YoutubeVideo"
import RequestEarlyAccess from "../RequestEarlyAccess/RequestEarlyAccess"
import HeaderFeatures from "../HeaderFeatures"
import lreWaterLogoSmall from "../../images/lre-water-logo-small.png"

const useStyles = makeStyles(theme => ({
  lreLink: {
    fontWeight: "bold",
    textDecoration: "none",
    color: theme.palette.text.primary
  },
  secondaryDescription: {
    fontSize: 18,
    lineHeight: 1.8,
    fontWeight: 400,
    color: props => props.color,
    textAlign: props => props.align,
    marginTop: theme.spacing(8)
  }
}))

const Header = ({ title, description, link, features, showLreBadge = true }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Grid container spacing={mobile ? 4 : 8} className={classes.root}>
      <Grid item xs={12} sm={5} className={classes.headerText}>
        <SectionTitle>{title}</SectionTitle>
        <SectionText>{description}</SectionText>
        <RequestEarlyAccess />
        <Button
          variant="contained"
          color="secondary"
          style={{ color: "#ffffff", marginLeft: 8 }}
          disableElevation
          href={process.env.GATSBY_APP_CALENDLY_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          Schedule a Demo
        </Button>
        {showLreBadge && (
          <Typography variant="body1" className={classes.secondaryDescription} paragraph>
            Brought to you by <a
              href="https://lrewater.com"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.lreLink}
            >
              LRE Water
            </a>
            <p>
              <a
                href="https://lrewater.com"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.lreLink}
              >
                <img src={lreWaterLogoSmall} alt={"LRE Water"}/>
              </a>
            </p>
          </Typography>
        )}
      </Grid>
      {features && (
        <Grid item xs={12} sm={7} className={classes.headerVideo}>
          <HeaderFeatures features={features} />
          {/* <YoutubeVideo link={link} /> */}
          {/* <Mockup /> */}
        </Grid>
      )}
    </Grid>
  )
}

Header.propTypes = {
  description: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
}

export default Header
