import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Box, Button, useTheme, useMediaQuery } from "@material-ui/core"
import SectionTitle from "../SectionTitle"
import SectionText from "../SectionText/SectionText"
import EmployeeCard from "../EmployeeCard/EmployeeCard"

const useStyles = makeStyles(theme => ({
  root: {},
}))

const AboutUs = ({ title, description, employees }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={4}>
          <Box bgcolor="#edefff" p={4} mb={mobile ? 4 : 0}>
            <SectionTitle>{title}</SectionTitle>
            <SectionText>{description}</SectionText>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              style={{ color: "#ffffff" }}
              disableElevation
              href={process.env.GATSBY_APP_CALENDLY_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              Schedule a Call
            </Button>
          </Box>
        </Grid>
        <Grid item hidden={mobile} md={1}></Grid>
        <Grid item xs={12} md={7}>
          <Grid container spacing={6}>
            {employees.map(employee => (
              <Grid key={employee.employeeName} item xs={12} sm={6}>
                <EmployeeCard
                  name={employee.employeeName}
                  position={employee.employeePosition}
                  bio={employee.employeeBio.employeeBio}
                  photo={employee.employeePhoto.file.url}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

AboutUs.propTypes = {
  description: PropTypes.string,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      employeeName: PropTypes.string,
      employeeBio: PropTypes.string,
      employeePhoto: PropTypes.string,
      employeePosition: PropTypes.string,
    })
  ),
  title: PropTypes.string,
}

export default AboutUs
