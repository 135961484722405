import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  Avatar,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  cardHeader: {
    backgroundColor: "#e9e9e9",
  },
  avatar: {
    backgroundColor: theme.palette.secondary.dark,
  },
  cardQuote: {
    fontStyle: "italic",
    fontSize: 18,
    lineHeight: 1.7,
  },
}))

const TestimonialCard = ({ title, quote, contact }) => {
  const classes = useStyles()
  return (
    <Card>
      <CardHeader
        title={title}
        className={classes.cardHeader}
        avatar={
          <Avatar className={classes.avatar}>{title.substring(0, 1)}</Avatar>
        }
      />
      <CardContent>
        <Typography variant="body1" className={classes.cardQuote} paragraph>
          {quote}
        </Typography>
        <Typography variant="body1">- {contact}</Typography>
      </CardContent>
    </Card>
  )
}

TestimonialCard.propTypes = {
  contact: PropTypes.string,
  quote: PropTypes.string,
  title: PropTypes.string,
}

export default TestimonialCard
