import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Grid } from "@material-ui/core"
import SectionTitle from "../SectionTitle"
import SectionText from "../SectionText/SectionText"
import TestimonialCard from "../TestimonialCard/TestimonialCard"

const useStyles = makeStyles(theme => ({
  root: {},
}))

const Testimonials = ({ title, description, testimonials }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Box maxWidth={768} ml="auto" mr="auto" mb={6}>
        <SectionTitle variant="alternate" color="#ffffff" align="center">
          {title}
        </SectionTitle>
        <SectionText color="#ffffff" align="center">
          {description}
        </SectionText>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          {testimonials.map(testimonial => (
            <Grid key={testimonial.testimonialTitle} item xs={12} md={6}>
              <TestimonialCard
                title={testimonial.client}
                quote={testimonial.testimonialText.testimonialText}
                contact={testimonial.contactName}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  )
}

Testimonials.propTypes = {
  description: PropTypes.string,
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      testimonialTitle: PropTypes.string,
      testimonialText: PropTypes.string,
      client: PropTypes.string,
      contactName: PropTypes.string,
    })
  ),
  title: PropTypes.string,
}

export default Testimonials
