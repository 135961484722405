import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import HomeLayout from "../../components/HomeLayout"
import SEO from "../../components/seo"
import TopNav from "../../components/TopNav/TopNav"
import Header from "../../components/Header/Header"
import { Container, useTheme } from "@material-ui/core"
import SectionContainer from "../../components/SectionContainer"
import Features from "../../components/Features/Features"
import AboutUs from "../../components/AboutUs/AboutUs"
import Testimonials from "../../components/Testimonials/Testimonials"
import ContactUs from "../../components/ContactUs/ContactUs"
import Footer from "../../components/Footer/Footer"

const setMenuItems = items => {
  return items.map(({ menuItemTitle, menuItemSlug }, index) => ({
    id: index,
    title: menuItemTitle,
    path: menuItemSlug,
    activePath: menuItemSlug,
    exact: true,
  }))
}

const Home = () => {
  const data = useStaticQuery(graphql`
    query homePageQuery {
      contentfulGsConfluenceHomePage(homePageTitle: { eq: "Main Home Page" }) {
        associatedMenu {
          menuItems {
            menuItemTitle
            menuItemSlug
          }
        }
        headerTitle
        headerDescription {
          headerDescription
        }
        headerFeatures {
          featureTitle
          featureImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        headerVideoLink
        featuresTitle
        featuresDescription {
          featuresDescription
        }
        features {
          featureTitle
          featureDescription {
            featureDescription
          }
          featureIcon {
            file {
              url
            }
          }
          featureType
          associatedSubFeatures {
            featureTitle
          }
        }
        aboutUsTitle
        aboutUsDescription {
          aboutUsDescription
        }
        aboutUsEmployees {
          employeeName
          employeePosition
          employeePhoto {
            file {
              url
            }
          }
          employeeBio {
            employeeBio
          }
        }
        testimonialsTitle
        testimonialsDescription {
          testimonialsDescription
        }
        testimonials {
          testimonialTitle
          testimonialText {
            testimonialText
          }
          client
          contactName
        }
      }
    }
  `)

  const theme = useTheme()

  const pageData = data.contentfulGsConfluenceHomePage
  const {
    headerTitle,
    headerDescription,
    headerVideoLink,
    headerFeatures,
    featuresTitle,
    featuresDescription,
    features,
    aboutUsTitle,
    aboutUsDescription,
    aboutUsEmployees,
    testimonialsTitle,
    testimonialsDescription,
    testimonials,
    associatedMenu,
  } = pageData

  return (
    <HomeLayout>
      <SEO title="Home" />
      <TopNav menuItems={setMenuItems(associatedMenu.menuItems)} />
      <SectionContainer maxWidth="lg">
        <Header
          title={headerTitle}
          description={headerDescription.headerDescription}
          link={headerVideoLink}
          features={headerFeatures}
        />
      </SectionContainer>
      <SectionContainer bgcolor="#1C2541">
        <Container maxWidth="lg">
          <Features
            title={featuresTitle}
            description={featuresDescription.featuresDescription}
            features={features}
          />
        </Container>
      </SectionContainer>
      <SectionContainer maxWidth="lg">
        <AboutUs
          title={aboutUsTitle}
          description={aboutUsDescription.aboutUsDescription}
          employees={aboutUsEmployees}
        />
      </SectionContainer>
      <SectionContainer bgcolor={theme.palette.secondary.main}>
        <Container maxWidth="lg">
          <Testimonials
            title={testimonialsTitle}
            description={testimonialsDescription.testimonialsDescription}
            testimonials={testimonials}
          />
        </Container>
      </SectionContainer>
      <SectionContainer maxWidth="sm">
        <ContactUs />
      </SectionContainer>
      <SectionContainer bgcolor="#f1f1f1">
        <Container maxWidth="md">
          <Footer menuItems={setMenuItems(associatedMenu.menuItems)} />
        </Container>
      </SectionContainer>
    </HomeLayout>
  )
}

export default Home
