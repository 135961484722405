import React, { useState } from "react"
import Img from "gatsby-image"
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"

const HeaderFeatures = ({ features }) => {
  const [activeFeature, setActiveFeature] = useState(features[0])
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Box>
      <Box width="100%">
        <Img
          style={{ maxWidth: "100%" }}
          fluid={activeFeature.featureImage.localFile.childImageSharp.fluid}
        />
      </Box>
      <Box align="center">
        <Typography variant="h5" gutterBottom>
          Featured Use Cases
        </Typography>
        {features.map(feat => (
          <Button
            key={feat.featureTitle}
            disableElevation
            variant={
              activeFeature.featureTitle === feat.featureTitle
                ? "contained"
                : "outlined"
            }
            color={
              activeFeature.featureTitle === feat.featureTitle
                ? "secondary"
                : "default"
            }
            onClick={() => setActiveFeature(feat)}
            fullWidth={mobile}
            style={{ marginRight: 8, marginBottom: 8 }}
          >
            {feat.featureTitle}
          </Button>
        ))}
      </Box>
    </Box>
  )
}

export default HeaderFeatures
