import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core"
import CheckIcon from "@material-ui/icons/CheckCircle"

const useStyles = makeStyles(theme => ({
  featureCard: {
    padding: theme.spacing(3),
    color: "#ffffff",
    backgroundColor: "#2E395A",
    borderRadius: 4,
    height: "100%",
  },
  featureIcon: {
    width: 140,
    height: 140,
    borderRadius: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(3),
    backgroundColor: "#424C6D",
  },
  subFeatureIcon: {
    color: theme.palette.primary.main,
    minWidth: 40,
  },
  btn: {
    backgroundColor: "#1C2541",
    color: "#ffffff",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#141C36",
      color: "#ffffff",
    },
  },
}))

const FeatureCard = ({ title, subFeatures }) => {
  const classes = useStyles()
  return (
    <div className={classes.featureCard}>
      {/* <div className={classes.featureIcon}></div> */}
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <List>
        {subFeatures.map(feat => (
          <ListItem key={feat.featureTitle}>
            <ListItemIcon className={classes.subFeatureIcon}>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText primary={feat.featureTitle} />
          </ListItem>
        ))}
      </List>
      {/* <Box mt={2}>
        <Button
          variant="contained"
          disableElevation
          className={classes.btn}
          component={Link}
          to="/products"
        >
          Learn More
        </Button>
      </Box> */}
    </div>
  )
}

FeatureCard.propTypes = {
  subFeatures: PropTypes.arrayOf(
    PropTypes.shape({
      featureTitle: PropTypes.string,
    })
  ),
  title: PropTypes.string,
}

export default FeatureCard
